import classNames from 'classnames'
import React from 'react'
import {FocusHandler} from '../../../../commons/components/focus-handler'
import {Direction} from '../../../../commons/constants/html'
import {ROOT_COMPONENT_ID} from '../../config/constants'
import {Router} from '../router'
import s from './app-container.scss'
import {AppContainerProps} from './index'

export const AppContainer = ({mobile, route, isRTL}: AppContainerProps) => (
  <div dir={isRTL ? Direction.RTL : Direction.LTR}>
    <FocusHandler />
    <div id={ROOT_COMPONENT_ID} className={classNames(s.root, {[s.mobile]: mobile})}>
      <Router route={route} mobile={mobile} />
    </div>
  </div>
)
