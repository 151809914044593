import {isMobile} from '../../../../commons/selectors/environment'
import {MemberPageContext} from '../../types/state'
import {connect} from '../connect'
import {Events as EventsPresentation} from './events'

export interface EventsProps extends EventsRuntimeProps {}

interface EventsRuntimeProps {
  mobile: boolean
}

const mapRuntime = ({state}: MemberPageContext): EventsRuntimeProps => ({
  mobile: isMobile(state),
})

export const Events = connect<{}, EventsRuntimeProps>(mapRuntime)(EventsPresentation)
