import React from 'react'
import {withWixSdk} from '@wix/yoshi-flow-editor'
import {TPAComponentsProvider} from 'wix-ui-tpa/TPAComponentsConfig'
import {ErrorPage} from '../../../../commons/components/error-page'
import {RuntimeContext} from '../../../../commons/components/runtime-context/runtime-context'
import {DatesProvider} from '../../../../commons/hooks/dates'
import {isMobile, isPreview} from '../../../../commons/selectors/environment'
import {MemberAppProps} from '../../interface'
import {shouldLoadMore} from '../../selectors/events'
import {AppContainer} from '../app-container'

const SCROLL_CUTOFF = 0.95

export default class AppRoot extends React.Component<MemberAppProps> {
  componentDidMount(): void {
    this.props.host.registerToScroll(({progressY}) => {
      if (progressY > SCROLL_CUTOFF && shouldLoadMore(this.props.state)) {
        this.props.actions.getEvents(this.props.state.tab)
      }
    })

    if (this.props.sdk?.Wix) {
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.SETTINGS_UPDATED, this.updateSettings)
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.EDIT_MODE_CHANGE, this.resetView)
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.SITE_PUBLISHED, this.onSitePublished)
    }
  }

  componentDidUpdate(prevProps: MemberAppProps) {
    const {actions, host, state} = this.props
    if (actions && host && state) {
      if (isPreview(state) && host.formFactor !== prevProps.host.formFactor) {
        actions.setFormFactor(host.formFactor)
      }
    }
  }

  componentWillUnmount() {
    if (this.props.sdk?.Wix) {
      // @ts-expect-error
      this.props.sdk.Wix.removeEventListener(this.props.sdk.Wix.Events.SETTINGS_UPDATED, this.updateSettings)
      // @ts-expect-error
      this.props.sdk.Wix.removeEventListener(this.props.sdk.Wix.Events.EDIT_MODE_CHANGE, this.resetView)
    }
  }

  updateSettings = action => this.props.actions.updateSettings(action)

  resetView = () => this.props.actions.resetToLiveView()

  onSitePublished = () => this.props.actions.updateComponent()

  render() {
    if (!this.props.state) {
      return <ErrorPage />
    }

    const mobile = isMobile(this.props.state)

    return (
      <RuntimeContext.Provider value={this.props}>
        <TPAComponentsProvider value={{mobile}}>
          <DatesProvider dates={this.props.state.dates}>
            <AppContainer isRTL={this.props.isRTL} />
          </DatesProvider>
        </TPAComponentsProvider>
      </RuntimeContext.Provider>
    )
  }
}

export const App = withWixSdk<any>(AppRoot)
