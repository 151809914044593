import React from 'react'
import {membersPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {hookToAttributeSelector} from '@wix/wix-events-commons-statics'
import {EventLink} from '../../styled-components/event-link'
import {CancelRsvpButtonProps} from './index'

export const CancelRsvpButton = ({event, mobile, cancelRsvp, t}: CancelRsvpButtonProps) => (
  <EventLink
    secondary={mobile}
    mobile={mobile}
    onClick={() => cancelRsvp({event, returnFocusElement: hookToAttributeSelector(DH.BUTTON_CANCEL_RSVP)})}
    data-hook={DH.BUTTON_CANCEL_RSVP}
  >
    {t('events.buttons.update-rsvp')}
  </EventLink>
)
