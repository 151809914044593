import {withTranslation, WithTranslation} from '@wix/yoshi-flow-editor'
import {isMobile} from '../../../../../commons/selectors/environment'
import {navigateToDetailsPage} from '../../../actions/navigation'
import {DetailedEvent, MemberPageContext} from '../../../types/state'
import {connect} from '../../connect'
import {DetailsButton as DetailsButtonPresentation} from './details-button'

export interface DetailsButtonProps extends DetailsButtonOwnProps, DetailsButtonRuntimeProps, WithTranslation {}

interface DetailsButtonOwnProps {
  event: DetailedEvent
}

interface DetailsButtonRuntimeProps {
  mobile: boolean
  navigateToDetailsPage: typeof navigateToDetailsPage
}

const mapRuntime = ({state, actions}: MemberPageContext) => ({
  mobile: isMobile(state),
  navigateToDetailsPage: actions.navigateToDetailsPage,
})

export const DetailsButton = connect<DetailsButtonOwnProps, DetailsButtonRuntimeProps>(mapRuntime)(
  withTranslation()(DetailsButtonPresentation),
)
